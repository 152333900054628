<template>
  <b-card>
    <FormView
      :data="$store.getters['service/detailData']"
      :fields="fields"
      @load="loadData"
    />
  </b-card>
</template>

<script>
import FormView from '@/layouts/components/FormView.vue'

import {
  BCard,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    FormView,
  },
  data() {
    return {
      // Fields
      fields: [
        {
          key: 'id',
          type: 'id',
          label: 'ID',
        },
        {
          key: 'name',
          label: 'Title',
          type: 'profile',
          image: 'thumbUrl',
        },
        {
          key: 'category_name',
          label: 'Category',
        },
        {
          key: 'game_name',
          label: 'Game',
        },
        {
          key: 'description',
          label: 'Description',
          visibility: ['view'],
        },
        {
          key: 'sort',
          label: 'Sort',
          type: 'number',
          prefix: '#',
        },
        {
          key: 'summary.averageStars',
          label: 'Rating',
          type: 'rating',
        },
      ],
    }
  },
  methods: {
    loadData(id) {
      return this.$store.dispatch('service/getDetail', id)
    },
  },
}
</script>

    <style scoped>

    </style>
